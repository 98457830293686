<template>
    <div>
        <div class="p-4 m-4 text-center" style="border-radius: 2rem;">
            <div style="width: 100%;" class="mb-4 pb-5">
                <img src="@/assets/images/darkroots_web.webp" alt height="450"  />
            </div>
            <div>
                <Loader class="mb-4" :loading="true" />
            </div>
        </div>
        
    </div>
</template>

<script>
import Loader from './loader.vue'
export default {
    data:() => ({}),
    components:{
        Loader,
    },
}
</script>

<style>

</style>