<script>
import { layoutComputed, adminMethods, adminComputed } from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";

import Loading from "@/components/widgets/loadingBlock.vue";

export default {
    components: {
        Vertical,
        Horizontal,
        Loading,
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
        ...adminComputed,
        hasCoreLoaded() {
            return this.core_loaded;
        },
        ready() {
            if (Object.keys(this.core).length > 0 && this.core_loaded) {
                return true;
            }
            return false;
        },
    },
    methods: {
        ...adminMethods,
    },
    mounted() {
        // if (!this.ready || this.is_core_loading == false) {
        //     this.loadCoreData();
        // }
    },
};
</script>

<template>
    <div>
        <Vertical
            v-if="ready && layoutType === 'vertical'"
            :layout="layoutType"
        >
            <slot />
        </Vertical>

        <Horizontal
            v-if="ready && layoutType === 'horizontal'"
            :layout="layoutType"
        >
            <slot />
        </Horizontal>

        <div v-if="!ready">
            <Loading />
        </div>
    </div>
</template>
