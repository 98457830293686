//eslint-disable-next-line
import store from "@/state/store";

export const adminMenuItems = [
    // {
    //     id: 2,
    //     label: "Dashboard",
    //     link: "/",
    //     icon: "fa-home",
    // },
    {
        id: 5,
        label: "Members",
        link: "/members",
        icon: "fa-store",
    },
    {
        id: 3,
        label: "Orders",
        icon: "fa-shopping-basket",
        subItems: [
            {
                id: 31,
                label: "View Orders",
                link: "/orders",
                parentId: 3,
            },
            {
                id: 32,
                label: "Create Order",
                link: "/create-order",
                parentId: 3,
            },

            //     // {
            //     //     id: 32,
            //     //     label: "Cape Town",
            //     //     link: "/orders/branch/1",
            //     //     parentId: 3,
            //     // },
            //     // {
            //     //     id: 32,
            //     //     label: "Johannesburg",
            //     //     link: "/orders/branch/2",
            //     //     parentId: 3,
            //     // },
        ],
    },
    {
        id: 116,
        label: "Products",
        icon: "fa-clipboard-list",
        subItems: [
            {
                id: 117,
                label: "Manage Products",
                link: "/products",
                parentId: 116,
            },
            {
                id: 118,
                label: "Manage Properties",
                link: "/properties",
                parentId: 116,
            },
            {
                id: 119,
                label: "Manage Groups",
                link: "/product-groups",
                parentId: 116,
            },
        ],
    },
    {
        id: 70,
        label: "CRM",
        icon: "fas fa-mail-bulk",
        subItems: [
            {
                id: 71,
                label: "Member Requests",
                link: "/member-requests",
                parentId: 6,
            },
            {
                id: 72,
                label: "Marketing",
                link: "/marketing",
                parentId: 6,
            },
            {
                id: 73,
                label: "Email Templates",
                link: "/email-templates",
                parentId: 6,
            },
            {
                id: 74,
                label: "Mailing Lists",
                link: "/mailing-lists",
                parentId: 6,
            },
        ],
    },
    {
        id: 6,
        label: "Users",
        link: "/users",
        icon: "fa-user-friends",
        subItems: [
            {
                id: 7,
                label: "Company users",
                link: "/users",
                parentId: 6,
            },
            {
                id: 8,
                label: "Rep Users",
                link: "/reps",
                parentId: 6,
            },
        ],
    },

    // {
    //     id: 8,
    //     label: "Test",
    //     link: "/test",
    //     icon: "fa-store",
    // },
];

export const productionMenuItems = [
    {
        id: 5,
        label: "Dashboard",
        link: `/orders`,
        icon: "fa-home",
    },
    // {
    //     id: 5,
    //     label: "Dashboard",
    //     link: `/clients/view/${store.getters["admin/getUserId"]}`,
    //     icon: "fa-home",
    // },
    // {
    //     id: 3,
    //     label: "Orders",
    //     link: "/orders",
    //     icon: "fa-shopping-basket",
    // },
    // {
    //     id: 2,
    //     label: "Dashboard",
    //     link: "/",
    //     icon: "fa-home",
    // },
    // {
    //     id: 116,
    //     label: "Products",
    //     icon: "fa-clipboard-list",
    //     subItems: [
    //         {
    //             id: 117,
    //             label: "Manage Products",
    //             link: "/products",
    //             parentId: 116,
    //         },
    //         {
    //             id: 118,
    //             label: "Manage Properties",
    //             link: "/properties",
    //             parentId: 116,
    //         },
    //     ],
    // },
    // {
    //     id: 6,
    //     label: "Users",
    //     link: "/users",
    //     icon: "fa-user-friends",
    //     subItems: [
    //         {
    //             id: 7,
    //             label: "Company users",
    //             link: "/users",
    //             parentId: 6,
    //         },
    //         {
    //             id: 8,
    //             label: "Rep Users",
    //             link: "/reps",
    //             parentId: 6,
    //         },
    //     ],
    // },
];

export const resellerMenuItems = [
    {
        id: 2,
        label: "Dashboard",
        link: `/reps/view/${store.state.admin.loggedIn_user_id}`,
        icon: "fa-home",
    },
];

export const stockistItems = [
    {
        id: 2,
        label: "Dashboard",
        link: `/clients/view/${store.getters["admin/getClientId"]}`,
        icon: "fa-home",
    },
    {
        id: 3,
        label: "Create Order",
        link: `/create-order/${store.getters["admin/getClientId"]}`,
        icon: "fa-shopping-basket",
        // subItems: [
        //     {
        //         id: 31,
        //         label: "View Orders",
        //         link: "/orders",
        //         parentId: 3,
        //     },
        //     {
        //         id: 32,
        //         label: "Create Order",
        //         link: "/create-order",
        //         parentId: 3,
        //     },

        //     // {
        //     //     id: 32,
        //     //     label: "Cape Town",
        //     //     link: "/orders/branch/1",
        //     //     parentId: 3,
        //     // },
        //     // {
        //     //     id: 32,
        //     //     label: "Johannesburg",
        //     //     link: "/orders/branch/2",
        //     //     parentId: 3,
        //     // },
        // ],
    },
];
