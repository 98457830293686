var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topnav"},[_c('div',{staticClass:"container-fluid"},[_c('nav',{staticClass:"navbar navbar-light navbar-expand-lg topnav-menu active"},[_c('div',{staticClass:"collapse navbar-collapse active",attrs:{"id":"topnav-menu-content"}},[_c('ul',{staticClass:"navbar-nav"},[_vm._l((_vm.menuItems),function(item,index){return [_c('li',{key:index,staticClass:"nav-item dropdown"},[(!item.subItems)?_c('router-link',{staticClass:"nav-link dropdown-toggle arrow-none",attrs:{"id":"topnav-components","to":item.link,"role":"button"}},[_c('i',{class:`bx ${item.icon} mr-2`}),_vm._v(_vm._s(_vm.$t(item.label))+" "),(_vm.hasItems(item))?_c('div',{staticClass:"arrow-down"}):_vm._e()]):_vm._e(),(item.subItems)?_c('a',{staticClass:"nav-link dropdown-toggle arrow-none",attrs:{"id":"topnav-components","role":"button"},on:{"click":function($event){return _vm.onMenuClick($event)}}},[_c('i',{class:`bx ${item.icon} mr-1`}),_vm._v(" "+_vm._s(_vm.$t(item.label))+" "),_c('div',{staticClass:"arrow-down"})]):_vm._e(),(_vm.hasItems(item))?_c('div',{staticClass:"dropdown-menu row",attrs:{"aria-labelledby":"topnav-dashboard"}},[_vm._l((item.subItems),function(subitem,index){return [(!_vm.hasItems(subitem))?_c('router-link',{key:index,staticClass:"col dropdown-item side-nav-link-ref",attrs:{"to":subitem.link}},[_vm._v(_vm._s(_vm.$t(subitem.label)))]):_vm._e(),(_vm.hasItems(subitem))?_c('div',{key:index,staticClass:"dropdown"},[_c('a',{staticClass:"dropdown-item dropdown-toggle",attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.onMenuClick($event)}}},[_vm._v(_vm._s(_vm.$t(subitem.label))+" "),_c('div',{staticClass:"arrow-down"})]),_c('div',{staticClass:"dropdown-menu"},[_vm._l((subitem.subItems),function(subSubitem,index){return [(
                                                        !_vm.hasItems(
                                                            subSubitem
                                                        )
                                                    )?_c('router-link',{key:index,staticClass:"dropdown-item side-nav-link-ref",attrs:{"to":subSubitem.link}},[_vm._v(_vm._s(_vm.$t(subSubitem.label)))]):_vm._e(),(
                                                        _vm.hasItems(subSubitem)
                                                    )?_c('div',{key:index,staticClass:"dropdown"},[_c('a',{staticClass:"dropdown-item dropdown-toggle",attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.onMenuClick(
                                                                $event
                                                            )}}},[_vm._v(_vm._s(_vm.$t( subSubitem.label ))+" "),_c('div',{staticClass:"arrow-down"})]),_c('div',{staticClass:"dropdown-menu"},[_vm._l((subSubitem.subItems),function(subSubSubitem,index){return [_c('router-link',{key:index,staticClass:"dropdown-item side-nav-link-ref",attrs:{"to":subSubSubitem.link,"routerLinkActive":"active"}},[_vm._v(_vm._s(_vm.$t( subSubSubitem.label )))])]})],2)]):_vm._e()]})],2)]):_vm._e()]})],2):_vm._e()],1)]})],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }